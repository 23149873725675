import { ComputedRef, unref } from 'vue';
import TagScanHandler from './TagScanHandler';
import TrackedItem from '@/domain/TrackedItem';

export default class WarehouseMovementTagScanHandler extends TagScanHandler {
    private action: (tag: TrackedItem) => void;

    private barcodesAlreadyScanned: Array<TrackedItem>;

    constructor(barcodesAlreadyScanned: Array<TrackedItem>, action:(tag: TrackedItem) => void) {
        super();
        this.action = action;
        this.barcodesAlreadyScanned = barcodesAlreadyScanned;
    }

    protected override async fetchTags(): Promise<TrackedItem[] | undefined> {
        return this.trackedItemService.searchTrackedItemsByBarcodes(this.scannerInput);
    }

    protected override handleScan(fetchedTags: TrackedItem[]): string[] {
        const duplicates = this.scannerInput.filter((b) => this.barcodesAlreadyScanned.filter(t => t.barcode === b).length > 0);
        fetchedTags.filter((tag) => !duplicates.includes(tag.barcode)).forEach((tag) => this.action(tag));
        return duplicates;
    }

    protected override handleScanManagedWarehouse(): Array<string> {
        return [];
    }
}
