import WarehouseReceivingApiService from '@/services/api/WarehouseReceivingApiService';
import ManagedWarehouseMovementDTO from '@/dtos/ManagedWarehouseMovementDTO';

export default class WarehouseReceivingService {
    private warehouseReceivingApiService: WarehouseReceivingApiService;

    constructor() {
        this.warehouseReceivingApiService = new WarehouseReceivingApiService();
    }

    public async managedWarehouseMovement(dto: ManagedWarehouseMovementDTO): Promise<{ success: boolean }> {
        const response = await this.warehouseReceivingApiService.managedWarehouseMovement(dto);

        return { success: response.success };
    }
}
