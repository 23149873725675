import Axios, { AxiosResponse } from 'axios';
import { API_SERVICE_URL } from '@/config/env';
import { DataAccessResponseClass } from '@/services/api/dataAccessResponse';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { WarehouseMovementData } from '@/modules/floortrak/types/WarehouseMovementData';

export default class WarehouseMovementApiService {
    public async commitWarehouseMovement(movementData: WarehouseMovementData): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await Axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/warehousemovement`,
            data: movementData,
        });

        return new DataAccessResponseClass<number>(response).response;
    }
}
