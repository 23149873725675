import { WarehouseMovementData } from '@/modules/floortrak/types/WarehouseMovementData';
import WarehouseMovementApiService from './api/WarehouseMovementApiService';

export default class WarehouseMovementService {
    private warehouseMovementApiService = new WarehouseMovementApiService();

    public async commitWarehouseMovement(movementData: WarehouseMovementData): Promise<{ success: boolean, qtyMoved?: number }> {
        const { success, data } = await this.warehouseMovementApiService.commitWarehouseMovement(movementData);
        return { success, qtyMoved: success ? data : undefined };
    }
}
